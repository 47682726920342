import {
  BaseQueryApi, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta, QueryReturnValue,
} from '@reduxjs/toolkit/query';
import { IVideoEvent } from '@th-common/interfaces/video/event-data';
import { IVideoFile } from '@th-common/interfaces/video/video-request';
import { apiRoot } from '@th-common/store/api';
import { IBaseQueryExtraOptions } from '@th-common/store/handlers';

interface IVideoDownloadDataParams {
  videoDownloadId: number;
  docToken: string;
  fileNames: string[];
}

const mapFileNamesToAPI = (
  params: IVideoDownloadDataParams,
  type: 'gps' | 'event',
): string[] => {
  return params.fileNames
    .map(
      (fileName) => `video-download/${params.videoDownloadId}/${type}/${fileName}?docToken=${params.docToken}`,
    );
};

const buildQueryFnForFileNames = <T>(
  type: 'gps' | 'event',
): ((
  _arg: IVideoDownloadDataParams,
  _queryApi: BaseQueryApi,
  _extraOptions: IBaseQueryExtraOptions,
  fetchWithBQ: (arg: string | FetchArgs) => Promise<QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta>>,
) => Promise<{ data: T } | { error: FetchBaseQueryError }>) => {
  return async (
    _arg: IVideoDownloadDataParams,
    _queryApi: BaseQueryApi,
    _extraOptions: IBaseQueryExtraOptions,
    fetchWithBQ: (arg: string | FetchArgs) => Promise<QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta>>,
  ) => {
    const results = await Promise.all(
      mapFileNamesToAPI(_arg, type).map((fileApiUrl) => fetchWithBQ(fileApiUrl)),
    );

    if (results) {
      const flattenedData = results.map((res) => res.data).flat();

      return { data: flattenedData as T };
    }
    return {
      error: {
        status: 'CUSTOM_ERROR',
        error: 'No data',
      } as FetchBaseQueryError,
    };
  };
};

export const videoDownloadApi = apiRoot.enhanceEndpoints({}).injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getToken: builder.query<{
      token: string;
      expiresIn: number;
    }, number>({
      query: (videoDownloadId) => `video-download/${videoDownloadId}/token`,
    }),
    getVideoDownload: builder.query<IVideoFile, { videoDownloadId: number; docToken: string }>({
      query: ({
        videoDownloadId,
        docToken,
      }) => {
        return {
          url: `video-download/${videoDownloadId}/index.json`,
          method: 'GET',
          params: {
            docToken,
          },
        };
      },
    }),
    getVideoDownloadTrackGpsData: builder.query<string[], IVideoDownloadDataParams>({
      queryFn: buildQueryFnForFileNames<string[]>('gps'),
    }),
    getVideoDownloadEventsData: builder.query<IVideoEvent[], IVideoDownloadDataParams>({
      queryFn: buildQueryFnForFileNames<IVideoEvent[]>('event'),
    }),
  }),
});
