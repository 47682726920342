export enum TAuthFeature {
  /// <summary>
  /// Authorization not required (AllowAnonymous)
  /// </summary>
  None = 0,

  /// <summary>
  /// View live images
  /// </summary>
  ViewLiveImages = 1,

  /// <summary>
  /// View stored images
  /// </summary>
  ViewStoredImages = 2,

  /// <summary>
  /// Export stored images
  /// </summary>
  ReviewExportImages = 3,

  /// <summary>
  /// Export video clips
  /// </summary>
  ReviewExportSequences = 4,

  /// <summary>
  /// Print stored images
  /// </summary>
  ReviewPrintImages = 6,

  /// <summary>
  /// Server logbook messages to keep
  /// </summary>
  ViewDeviceLog = 7,

  /// <summary>
  /// Log messages to keep
  /// </summary>
  ViewSoftwareLog = 8,

  /// <summary>
  /// Add review marker
  /// </summary>
  ReviewAddMarker = 9,

  /// <summary>
  /// Delete review marker
  /// </summary>
  ReviewDeleteMarker = 10,

  /// <summary>
  /// Configure devices
  /// </summary>
  ConfigureDevice = 11,

  /// <summary>
  /// Export configuration
  /// </summary>
  ConfigExport = 12,

  /// <summary>
  /// Apply configuration settings
  /// </summary>
  ApplyConfig = 13,

  /// <summary>
  /// View status information
  /// </summary>
  ViewStatus = 14,

  /// <summary>
  /// View service operations
  /// </summary>
  ViewService = 15,

  /// <summary>
  /// Synchronize server time
  /// </summary>
  ServiceSyncTime = 16,

  /// <summary>
  /// Format disk
  /// </summary>
  ServiceFormatDisk = 17,

  /// <summary>
  /// Update firmware
  /// </summary>
  ServiceUpdateFirmware = 18,

  /// <summary>
  /// Reboot system
  /// </summary>
  ServiceReboot = 19,

  /// <summary>
  /// Export logbook
  /// </summary>
  ExportLogbook = 20,

  /// <summary>
  /// Export AVI clips
  /// </summary>
  ReviewExportAVI = 21,

  /// <summary>
  /// Modify track
  /// </summary>
  ReviewModifyTrack = 22,

  /// <summary>
  /// Delete track
  /// </summary>
  ReviewDeleteTrack = 23,

  /// <summary>
  /// Export live images
  /// </summary>
  LiveExportImages = 24,

  /// <summary>
  /// Print live images
  /// </summary>
  LivePrintImages = 25,

  /// <summary>
  /// Edit users
  /// </summary>
  EditUsers = 26,

  /// <summary>
  /// Edit roles
  /// </summary>
  EditRoles = 27,

  /// <summary>
  /// Change password
  /// </summary>
  ChangePassword = 28,

  /// <summary>
  /// Restore default settings
  /// </summary>
  DefaultSettings = 29,

  /// <summary>
  /// Import configuration
  /// </summary>
  ConfigImport = 30,

  /// <summary>
  /// Expert mode configuration
  /// </summary>
  ConfigExpertMode = 31,

  /// <summary>
  /// Add device
  /// </summary>
  AddVehicleToFleet = 32,

  /// <summary>
  /// Remove device
  /// </summary>
  RemoveVehicleFromFleet = 33,

  /// <summary>
  /// View fleet database
  /// </summary>
  ViewFleetDatabase = 34,

  /// <summary>
  /// View fleet status
  /// </summary>
  ViewFleetStatus = 35,

  /// <summary>
  /// View jobs
  /// </summary>
  ViewJobs = 36,

  /// <summary>
  /// Add or edit jobs
  /// </summary>
  AddEditJobs = 37,

  /// <summary>
  /// Delete jobs
  /// </summary>
  DeleteJobs = 38,

  /// <summary>
  /// Video query configuration
  /// </summary>
  VideoQueries = 39,

  /// <summary>
  /// View video queries
  /// </summary>
  ViewVideoQueries = 40,

  /// <summary>
  /// Request video queries
  /// </summary>
  RequestVideoQueries = 41,

  /// <summary>
  /// Delete video queries
  /// </summary>
  DeleteVideoQueries = 42,

  /// <summary>
  /// Configure client-server settings
  /// </summary>
  ConfigureClientServerSettings = 43,

  /// <summary>
  /// View service diagnostics
  /// </summary>
  ServiceViewDiagnostics = 44,

  /// <summary>
  /// View service status
  /// </summary>
  ServiceViewStatus = 45,

  /// <summary>
  /// View service logs
  /// </summary>
  ServiceViewLog = 46,

  /// <summary>
  /// Set reference image
  /// </summary>
  SetReferenceImage = 47,

  /// <summary>
  /// Accept or reject verification image
  /// </summary>
  AcceptRejectVerificationImage = 48,

  /// <summary>
  /// Show debug information
  /// </summary>
  ShowDebugInfo = 49,

  /// <summary>
  /// Manage template configurations
  /// </summary>
  ManageTemplateConfigs = 50,

  /// <summary>
  /// Asset management
  /// </summary>
  AssetManagement = 51,

  /// <summary>
  /// View assets
  /// </summary>
  AssetManagementViewAssets = 52,

  /// <summary>
  /// Edit assets
  /// </summary>
  AssetManagementEditAssets = 53,

  /// <summary>
  /// Manage template configurations
  /// </summary>
  AssetManagementManageTemplates = 54,

  /// <summary>
  /// Video storage management
  /// </summary>
  VideoStorageManagement = 55,

  /// <summary>
  /// Ridership validation
  /// </summary>
  RidershipValidation = 56,

  /// <summary>
  /// Override job network scope
  /// </summary>
  OverrideJobNetworkScope = 57,

  /// <summary>
  /// Geographical queries
  /// </summary>
  GeographicalQueries = 58,

  /// <summary>
  /// View live images over cellular network
  /// </summary>
  ViewLiveImagesOverCellular = 59,

  /// <summary>
  /// View stored images over cellular network
  /// </summary>
  ViewStoredImagesOverCellular = 60,

  /// <summary>
  /// Change download job network scope
  /// </summary>
  ChangeDownloadJobNetworkScope = 61,

  /// <summary>
  /// Manage image blurring
  /// </summary>
  ReviewManageBlurring = 62,

  /// <summary>
  /// View camera statistics
  /// </summary>
  ViewCameraStatistics = 63,
}
