
import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import { videoDownloadApi } from '@th-common/api/video-download.api';
import { TPlaybackSpeedSettingsValue } from '@th-common/enums/video-playback/playback-speed.enum';
import { TViewLayout } from '@th-common/interfaces/player/player';
import { IVideoAdjustments } from '@th-common/interfaces/player/video-source-info';
import { IVideoBookmark } from '@th-common/interfaces/video/bookmark';
import {
  ICameraHlsData, IVideoAvSource, IVideoTrack, IVideoTrackHls,
} from '@th-common/interfaces/video/video-request';
import { StorageUtils } from '@th-common/utils/storage';
import dayjs, { Dayjs } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

import { TimelineScaleUtils } from './utils/timeline-scale-utils';
import { VideoPlaybackUtils } from './utils/video-player-utils';
import { startTime } from './gps-fake-data';
import { IVideoPlaybackState, TPlayerMode } from './video-playback-state.interface';

dayjs.extend(isBetween);

const initialState: IVideoPlaybackState = {
  playerMode: TPlayerMode.Archive,
  docToken: '',
  deviceName: '',
  tracks: [],
  cameraHlsDataByTrack: [],
  eventJSONList: [],
  currentTrackIndex: 0,
  cameras: [],
  audioSources: [],
  dirHandle: null,
  blurring: null,
  playerCurrentTimeRelated: 0,
  currentTimelineValue: 0,
  masterPlayerNumber: -1,
  clipValue: [0, 0],
  clipDateTime: ['', ''],
  videoDownloadId: 123,
  videoRequestStartDayjs: dayjs(),
  videoRequestEndDayjs: dayjs(),
  videoRequestMin: 0,
  videoRequestMax: 0,
  maxFps: 15,
  bookmarks: [],
  activeBookmarkIndex: null,
  viewLayout: TViewLayout.Grid,
  isMetaDataPanelOpen: false,
  // Player
  showClip: false,
  hoveredFrame: null,
  hoveredFrameTooltip: '',
  hoveredFramePositionX: 0,
  scaleValue: [0, 0],
  playingForward: false,
  playingBackward: false,
  playbackSpeed: TPlaybackSpeedSettingsValue.Speed1,
  fromFolder: false,
  bigCameraNumber: null,
  cameraNumbersCanPlay: [],
  cameraNumbersBuffering: [],
};

export const slice = createSlice({
  name: 'videoPlayback',
  initialState,
  reducers: {
    setVideoDownloadId: (state, { payload }: { payload: number }) => {
      state.videoDownloadId = payload;
    },
    setPlayerMode: (state, { payload }: { payload: TPlayerMode }) => {
      state.playerMode = payload;
    },
    setCurrentTimelineValueMs: (state, { payload }: { payload: number }) => {
      const trackIndex = VideoPlaybackUtils.getTrackIndexByTimelineValue(
        payload,
        state.tracks,
        state.currentTrackIndex,
      );

      const stateChanges = VideoPlaybackUtils.returnStateAfterTrackChange(
        {
          ...state,
          currentTrackIndex: trackIndex,
          currentTimelineValue: payload,
        },
      );
      Object.assign(state, stateChanges);
    },
    setCurrentPlayerTime: (state, { payload }: { payload: number }) => {
      if (state.currentTrackIndex === null) {
        return;
      }

      const playerTimeAsTimelineValue = VideoPlaybackUtils.getTimelineValueByPlayerTime(
        payload,
        state.cameraHlsDataByTrack[state.currentTrackIndex][state.masterPlayerNumber].startTimelineValue,
      );

      if (state.playingForward && playerTimeAsTimelineValue > state.videoRequestMax) {
        const stateChanges = VideoPlaybackUtils.returnStateAfterTrackChange(
          {
            ...state,
            currentTimelineValue: state.videoRequestMax,
          },
        );
        Object.assign(state, stateChanges);
        state.playingForward = false;
        return;
      }

      if (state.playingBackward && playerTimeAsTimelineValue <= state.videoRequestMin) {
        const stateChanges = VideoPlaybackUtils.returnStateAfterTrackChange(
          {
            ...state,
            currentTimelineValue: state.videoRequestMin,
          },
        );
        Object.assign(state, stateChanges);
        state.playingBackward = false;
        return;
      }

      const cameraNumbersCanPlayStateChanges = VideoPlaybackUtils.updateTimelineValue({
        ...state,
        currentTimelineValue: playerTimeAsTimelineValue,
      });
      Object.assign(state, cameraNumbersCanPlayStateChanges);
    },
    syncPlayerCurrentTimeToTimeline: (state) => {
      const currentTrackIndex = state.currentTrackIndex;

      if (currentTrackIndex === null) {
        return;
      }
      const timelineRelatedToTrack = state.currentTimelineValue - state.tracks[currentTrackIndex].startTimelineValue;

      const playerCurrentTimeRelated = VideoPlaybackUtils.roundTimelineValue(timelineRelatedToTrack) / 1000;
      state.playerCurrentTimeRelated = +playerCurrentTimeRelated.toFixed(6);
    },
    setMasterPlayerNumber: (state, { payload }: { payload: number }) => {
      state.masterPlayerNumber = payload;
    },
    stepFrameForward: (state) => {
      const currentTrackIndex = state.currentTrackIndex;

      if (currentTrackIndex === null) {
        return;
      }

      const masterPlayerEndTimelineValue = state.cameraHlsDataByTrack[currentTrackIndex][state.masterPlayerNumber].endTimelineValue;

      if (state.currentTimelineValue === masterPlayerEndTimelineValue) {
        if (currentTrackIndex < state.tracks.length - 1) {
          const stateChanges = VideoPlaybackUtils.returnStateAfterTrackChange({
            ...state,
            currentTrackIndex: currentTrackIndex + 1,
          });
          Object.assign(state, stateChanges);
        }
        return;
      }

      const timelineValue = VideoPlaybackUtils.roundTimelineValue(state.currentTimelineValue - (1000 / state.maxFps));
      const currentTimelineValue = timelineValue > masterPlayerEndTimelineValue ? masterPlayerEndTimelineValue : timelineValue;

      const playerCurrentTimeRelated = (currentTimelineValue - state.cameraHlsDataByTrack[currentTrackIndex][state.masterPlayerNumber].startTimelineValue) / 1000;
      state.playerCurrentTimeRelated = +playerCurrentTimeRelated.toFixed(6);
    },
    stepFrameBackward: (state) => {
      const currentTrackIndex = state.currentTrackIndex;

      if (currentTrackIndex === null) {
        return;
      }

      const masterPlayerStartTimelineValue = state.cameraHlsDataByTrack[currentTrackIndex][state.masterPlayerNumber].startTimelineValue;

      if (state.currentTimelineValue <= masterPlayerStartTimelineValue) {
        if (currentTrackIndex > 0) {
          const stateChanges = VideoPlaybackUtils.returnStateAfterTrackChange({
            ...state,
            currentTrackIndex: currentTrackIndex - 1,
          });
          Object.assign(state, stateChanges);
        }
        return;
      }

      const timelineValue = state.currentTimelineValue - (1000 / state.maxFps);

      const playerCurrentTimeRelated = VideoPlaybackUtils.roundTimelineValue(timelineValue - state.tracks[currentTrackIndex].startTimelineValue) / 1000;
      state.playerCurrentTimeRelated = +playerCurrentTimeRelated.toFixed(6);
    },
    nextTrack: (state) => {
      if (state.currentTrackIndex === null || state.currentTrackIndex === state.tracks.length - 1) {
        return;
      }

      const newTrackIndex = state.currentTrackIndex + 1;
      const stateChanges = VideoPlaybackUtils.returnStateAfterTrackChange({
        ...state,
        currentTrackIndex: newTrackIndex,
        currentTimelineValue: state.tracks[newTrackIndex].startTimelineValue,
      });
      Object.assign(state, stateChanges);
    },
    setCurrentDateTime: (state, { payload }: { payload: Dayjs }) => {
      const currentTimelineValue = payload.diff(state.videoRequestStartDayjs, 'ms');

      const trackIndex = VideoPlaybackUtils.getTrackIndexByTimelineValue(
        currentTimelineValue,
        state.tracks,
        state.currentTrackIndex,
      );

      const stateChanges = VideoPlaybackUtils.returnStateAfterTrackChange(
        {
          ...state,
          currentTrackIndex: trackIndex,
          currentTimelineValue,
        },
      );
      Object.assign(state, stateChanges);
    },
    setClipValue: (state, { payload }: { payload: [number, number] }) => {
      state.clipValue = payload;
    },
    setCenterFrame: (state) => {
      const scaleDiff = (state.scaleValue[1] - state.scaleValue[0]) / 2;
      let scaleStart = state.currentTimelineValue - scaleDiff;
      let scaleEnd = state.currentTimelineValue + scaleDiff;

      if (scaleStart < state.videoRequestMin) {
        scaleEnd += (state.videoRequestMin - scaleStart);
      }

      if (scaleEnd > state.videoRequestMax) {
        scaleStart -= (scaleEnd - state.videoRequestMax);
      }

      state.scaleValue = [
        Math.max(scaleStart, state.videoRequestMin),
        Math.min(scaleEnd, state.videoRequestMax),
      ];
    },
    addBookmark: (state) => {
      const bookmark = {
        value: state.videoRequestStartDayjs.clone().add(state.currentTimelineValue, 'ms').toISOString(),
        note: '',
        saved: false,
      };

      if (state.activeBookmarkIndex !== null) {
        const activeBookmark = state.bookmarks[state.activeBookmarkIndex];
        if (activeBookmark.saved) {
          state.bookmarks = [...state.bookmarks, bookmark];
          state.activeBookmarkIndex = state.bookmarks.length - 1;
          return;
        }
        state.bookmarks[state.activeBookmarkIndex] = bookmark;
        return;
      }

      state.bookmarks = [...state.bookmarks, bookmark];
      state.activeBookmarkIndex = state.bookmarks.length - 1;
    },
    saveActiveBookmark: (state, { payload }: { payload: IVideoBookmark }) => {
      if (state.activeBookmarkIndex !== null) {
        state.bookmarks[state.activeBookmarkIndex] = {
          ...payload,
          saved: true,
        };
      }

      state.activeBookmarkIndex = null;
    },
    cancelActiveBookmark: (state) => {
      if (state.activeBookmarkIndex !== null && !state.bookmarks[state.activeBookmarkIndex].saved) {
        state.bookmarks = state.bookmarks.filter((_, index) => index !== state.activeBookmarkIndex);
      }

      state.activeBookmarkIndex = null;
    },
    clearBookmarks: (state) => {
      state.bookmarks = [];
      state.activeBookmarkIndex = null;
    },
    setActiveBookmark: (state, { payload }: { payload: number | null }) => {
      state.activeBookmarkIndex = payload === null ? null : payload;
    },
    cameraSourcesVisibility: (state, { payload }: { payload: string[] }) => {
      state.cameras = state.cameras.map((camera) => {
        return {
          ...camera,
          isPlayerHide: !payload.includes(camera.name),
        };
      });

      if (state.currentTrackIndex !== null) {
        state.maxFps = VideoPlaybackUtils.getMaxFpsByTrack(state.cameraHlsDataByTrack, state.currentTrackIndex, state.cameras);
      }
    },
    hidePlayer: (state, { payload }: { payload: number }) => {
      state.cameras = state.cameras.map((camera) => {
        if (camera.number === payload) {
          return {
            ...camera,
            isPlayerHide: true,
          };
        }
        return camera;
      });

      if (state.currentTrackIndex !== null) {
        state.maxFps = VideoPlaybackUtils.getMaxFpsByTrack(state.cameraHlsDataByTrack, state.currentTrackIndex, state.cameras);
      }
    },
    showPlayer: (state, { payload }: { payload: string }) => {
      state.cameras = state.cameras.map((camera) => {
        if (camera.name === payload) {
          return {
            ...camera,
            isPlayerHide: false,
          };
        }
        return camera;
      });
    },
    setViewLayout: (state, { payload }: { payload: TViewLayout }) => {
      state.viewLayout = payload;

      if (payload === TViewLayout.List) {
        state.bigCameraNumber = null;
      }
    },
    cameraNumberBuffering: (state, { payload }: { payload: number }) => {
      if (state.cameraNumbersBuffering.includes(payload)) {
        return;
      }

      state.cameraNumbersBuffering = [...state.cameraNumbersBuffering, payload];
    },
    cameraNumberBuffered: (state, { payload }: { payload: number }) => {
      state.cameraNumbersBuffering = state.cameraNumbersBuffering.filter((cameraNumber) => cameraNumber !== payload);
    },

    toggleMetaDataPanel: (state, { payload }: { payload: boolean }) => {
      state.isMetaDataPanelOpen = payload;
    },
    setVideoAdjustments: (state, { payload }: { payload: { cameraNumber: number; videoParams: IVideoAdjustments } }) => {
      const cameraInfo = state.cameras.find((camera) => camera.number === payload.cameraNumber);
      if (cameraInfo) {
        cameraInfo.brightness = payload.videoParams.brightness;
        cameraInfo.contrast = payload.videoParams.contrast;
        cameraInfo.saturate = payload.videoParams.saturate;
      }
    },
    setVideoSource: (state, { payload }: { payload: { file: File; durationInSeconds: number } }) => {
      const videoRequestStartDayjs = startTime;
      const videoRequestEndDayjs = startTime.add(payload.durationInSeconds, 'second');
      const videoRequestMin = 0;
      const videoRequestMax = videoRequestEndDayjs.diff(videoRequestStartDayjs, 'ms');
      const cameraAvSources: IVideoAvSource[] = [
        {
          name: 'File Source',
          type: 'IPCamera',
          number: 1,
        },
      ];
      state.fromFolder = true;
      state.currentTimelineValue = 0;
      state.scaleValue = [videoRequestMin, videoRequestMax];
      state.videoRequestMin = videoRequestMin;
      state.videoRequestMax = videoRequestMax;
      state.deviceName = 'File Source';
      state.cameras = cameraAvSources.map((source) => {
        return {
          name: source.name,
          number: source.number,
          isPlayerHide: false,
          isVideoAdjustmentsOpen: false,
          brightness: 1,
          contrast: 1,
          saturate: 1,
        };
      });
      state.tracks = [
        {
          start: startTime.toISOString(),
          end: startTime.add(payload.durationInSeconds, 'second').toISOString(),
          startTimelineValue: 0,
          endTimelineValue: videoRequestMax,
          severity: 1,
        },
      ];
      state.cameraHlsDataByTrack = [
        [
          {
            number: 1,
            src: URL.createObjectURL(payload.file),
            fps: 15,
            startTimelineValue: 0,
            endTimelineValue: videoRequestMax,
            startRelatedToTrack: 0,
          },
        ],
      ];
      state.videoRequestStartDayjs = videoRequestStartDayjs;
      state.videoRequestEndDayjs = videoRequestEndDayjs;
    },
    resetVideoAdjustments: (state, { payload }: { payload: { cameraNumber: number } }) => {
      const cameraInfo = state.cameras.find((camera) => camera.number === payload.cameraNumber);
      if (cameraInfo) {
        cameraInfo.brightness = 1;
        cameraInfo.contrast = 1;
        cameraInfo.saturate = 1;
      }
    },
    toggleVideoAdjustmentsPanel: (state, { payload }: { payload: { cameraNumber: number; isOpen?: boolean } }) => {
      const cameraInfo = state.cameras.find((camera) => camera.number === payload.cameraNumber);
      if (cameraInfo) {
        if (payload.isOpen !== undefined) {
          cameraInfo.isVideoAdjustmentsOpen = payload.isOpen;
        } else {
          cameraInfo.isVideoAdjustmentsOpen = !cameraInfo.isVideoAdjustmentsOpen;
        }
      }
    },
    // Player
    toggleClip: (state) => {
      state.showClip = !state.showClip;
    },
    setHoveredFrame: (state, { payload }: { payload: { hoveredFrame: number | null; timeFormat: string; x: number | null } }) => {
      if (payload.x === state.hoveredFramePositionX) {
        return;
      }

      if (payload.x === null) {
        payload.x = state.hoveredFramePositionX;
      }

      state.hoveredFramePositionX = payload.x;

      if (payload.hoveredFrame === state.hoveredFrame) {
        return;
      }

      if (payload.hoveredFrame === null) {
        state.hoveredFrame = null;
        state.hoveredFrameTooltip = '';
        return;
      }

      state.hoveredFrame = payload.hoveredFrame;
      state.hoveredFrameTooltip = state.videoRequestStartDayjs.add(payload.hoveredFrame, 'ms').format(payload.timeFormat);
    },
    setScaleValue: (state, { payload }: { payload: [number, number] }) => {
      const newScaleRange = payload;

      const reachedMinScaleValue = TimelineScaleUtils.reachedMinScaleValue(newScaleRange);

      if (reachedMinScaleValue) {
        return;
      }

      state.scaleValue = [
        Math.max(payload[0], state.videoRequestMin),
        Math.min(payload[1], state.videoRequestMax),
      ];
    },
    playingForward: (state) => {
      state.playingForward = true;
      state.playingBackward = false;
    },
    playingBackward: (state) => {
      state.playingForward = false;
      state.playingBackward = true;
    },
    pauseVideo: (state) => {
      state.playingForward = false;
      state.playingBackward = false;
    },
    setPlaybackSpeed: (state, { payload }: { payload: TPlaybackSpeedSettingsValue }) => {
      state.playbackSpeed = payload;
    },
    skipSegmentToEnd: (state) => {
      const trackIndex = state.tracks.findIndex(track => state.currentTimelineValue < track.endTimelineValue);
      if (trackIndex !== -1) {
        const stateChanges = VideoPlaybackUtils.returnStateAfterTrackChange(
          {
            ...state,
            currentTrackIndex: trackIndex,
            currentTimelineValue: state.tracks[trackIndex].endTimelineValue!,
          },
        );
        Object.assign(state, stateChanges);
      }
    },
    skipSegmentToStart: (state) => {
      let trackIndex = state.tracks.length - 1;

      for (let i = state.tracks.length - 1; i >= 0; i--) {
        if (state.currentTimelineValue > state.tracks[i].startTimelineValue) {
          trackIndex = i;
          break;
        }
      }
      if (trackIndex !== -1) {
        const stateChanges = VideoPlaybackUtils.returnStateAfterTrackChange(
          {
            ...state,
            currentTrackIndex: trackIndex,
            currentTimelineValue: state.tracks[trackIndex].startTimelineValue,
          },
        );
        Object.assign(state, stateChanges);
      }
    },
    zoomIn: (state) => {
      let zoomStartDiff = (state.scaleValue[1] - state.scaleValue[0]) * 0.02;
      let zoomEndDiff = (state.scaleValue[1] - state.scaleValue[0]) * 0.02;

      if (state.hoveredFrame !== null) {
        [
          zoomStartDiff,
          zoomEndDiff,
        ] = TimelineScaleUtils.getZoomDiffRelatedToHoveredFrame(zoomStartDiff, state.hoveredFrame, state.scaleValue);
      }

      const scaleMinValue = state.scaleValue[0] + zoomStartDiff;
      const scaleMaxValue = state.scaleValue[1] - zoomEndDiff;

      const reachedMinScaleValue = TimelineScaleUtils.reachedMinScaleValue([scaleMinValue, scaleMaxValue]);

      if (reachedMinScaleValue) {
        return;
      }

      state.scaleValue = [
        Math.min(scaleMinValue, scaleMaxValue),
        Math.max(scaleMaxValue, scaleMinValue),
      ];
    },
    zoomOut: (state) => {
      let zoomStartDiff = (state.scaleValue[1] - state.scaleValue[0]) * 0.02;
      let zoomEndDiff = (state.scaleValue[1] - state.scaleValue[0]) * 0.02;

      if (state.hoveredFrame !== null) {
        [
          zoomStartDiff,
          zoomEndDiff,
        ] = TimelineScaleUtils.getZoomDiffRelatedToHoveredFrame(zoomStartDiff, state.hoveredFrame, state.scaleValue);
      }

      state.scaleValue = [
        Math.max(state.scaleValue[0] - zoomStartDiff, state.videoRequestMin),
        Math.min(state.scaleValue[1] + zoomEndDiff, state.videoRequestMax),
      ];
    },
    setBigCameraNumber: (state, { payload }: { payload: number | null }) => {
      state.bigCameraNumber = state.bigCameraNumber === payload ? null : payload;
    },
    reset: () => initialState,
  },
  extraReducers: (builder: ActionReducerMapBuilder<IVideoPlaybackState>) => {
    builder
      .addMatcher(videoDownloadApi.endpoints.getToken.matchFulfilled, (state, { payload }) => {
        state.docToken = payload.token;
      }).addMatcher(videoDownloadApi.endpoints.getVideoDownload.matchFulfilled, (state, { payload }) => {
        const cameraAvSources = payload.avSources.filter((source) => source.type === 'IPCamera');
        const cameraAvSourceNumbers = cameraAvSources.map((source) => source.number);
        const audioAVSources = payload.avSources.filter((source) => source.type === 'IPAudio');
        const apiBaseUrl = (StorageUtils.search('env', 'sessionStorage') as { apiBaseUrl: string }).apiBaseUrl;
        const videoRequestStartDayjs = dayjs.parseZone(payload.start);
        const videoRequestEndDayjs = dayjs.parseZone(payload.end);
        const videoRequestMin = 0;
        const videoRequestMax = videoRequestEndDayjs.diff(videoRequestStartDayjs, 'ms');

        state.scaleValue = [videoRequestMin, videoRequestMax];
        state.videoRequestMin = videoRequestMin;
        state.videoRequestMax = videoRequestMax;
        state.deviceName = payload.device;
        state.cameras = cameraAvSources.map((source) => {
          return {
            name: source.name,
            number: source.number,
            isPlayerHide: false,
            isVideoAdjustmentsOpen: false,
            brightness: 1,
            contrast: 1,
            saturate: 1,
          };
        });
        const {
          tracks,
          cameraHlsDataByTrack,
        } = payload.tracks.reduce((acc, track: IVideoTrack) => {
          const trackStartDayjs = dayjs.parseZone(track.start);
          const trackEndDayjs = dayjs.parseZone(track.end);

          const trackStartTimelineValue = trackStartDayjs.diff(videoRequestStartDayjs, 'ms');

          acc.tracks.push({
            start: track.start,
            end: track.end,
            startTimelineValue: trackStartTimelineValue,
            endTimelineValue: trackEndDayjs.diff(videoRequestStartDayjs, 'ms'),
            severity: track.severity,
          });

          const trackCameraSources = track.avSources.filter(avSource => cameraAvSourceNumbers.includes(avSource.avSource));
          acc.cameraHlsDataByTrack.push(trackCameraSources.reduce((hlsDataByTrack, avSource) => {
            const hlsStartTimelineValue = dayjs.parseZone(avSource.start).diff(videoRequestStartDayjs, 'ms');
            hlsDataByTrack[avSource.avSource] = {
              number: avSource.avSource,
              src: `${apiBaseUrl}/video-download/${state.videoDownloadId}/${avSource.playlist}`,
              fps: avSource.fps,
              startTimelineValue: hlsStartTimelineValue,
              endTimelineValue: dayjs.parseZone(avSource.end).diff(videoRequestStartDayjs, 'ms'),
              startRelatedToTrack: hlsStartTimelineValue - trackStartTimelineValue,
            };

            return hlsDataByTrack;
          }, {} as Record<number, ICameraHlsData>));

          return acc;
        }, {
          tracks: [] as IVideoTrackHls[],
          cameraHlsDataByTrack: [] as Record<number, ICameraHlsData>[],
        });

        state.tracks = tracks;
        state.maxFps = VideoPlaybackUtils.getMaxFpsByTrack(cameraHlsDataByTrack, 0, state.cameras);
        const stateChanges = VideoPlaybackUtils.returnStateAfterTrackChange({
          ...state,
          currentTrackIndex: 0,
          currentTimelineValue: 0,
          cameraHlsDataByTrack,
          tracks,
        });
        Object.assign(state, stateChanges);
        state.cameraHlsDataByTrack = cameraHlsDataByTrack;
        state.eventJSONList = payload.event || [];
        state.videoRequestStartDayjs = videoRequestStartDayjs;
        state.videoRequestEndDayjs = videoRequestEndDayjs;
        state.bookmarks = [];
        state.activeBookmarkIndex = null;

        state.blurring = payload.blurring;
      });
  },
});

export default slice.reducer;
