
import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import { videoDownloadApi } from '@th-common/api/video-download.api';
import { TMenuItem } from '@th-common/interfaces/navigation-menu';
import { IGpsData } from '@th-common/interfaces/video/gps-data';
import dayjs, { Dayjs } from 'dayjs';

import { gpsTraceRanges } from './data/gps-trace-ranges';

export interface IVideoPlaybackGpsState {
  videoRequestStartDayjs: Dayjs;
  videoRequestEndDayjs: Dayjs;
  gpsJSONList: string[];
  gpsData: IGpsData[];
  currentTraceRange: TMenuItem;
  isFetchingGpsData: boolean;
}

const initialState: IVideoPlaybackGpsState = {
  videoRequestStartDayjs: dayjs(),
  videoRequestEndDayjs: dayjs(),
  gpsJSONList: [],
  gpsData: [],
  currentTraceRange: gpsTraceRanges[0],
  isFetchingGpsData: false,
};

export const slice = createSlice({
  name: 'videoPlaybackGps',
  initialState,
  reducers: {
    setCurrentTraceRange: (state, { payload }: { payload: TMenuItem }) => {
      state.currentTraceRange = payload;
    },
    setIsFetchingGpsData: (state, { payload }: { payload: boolean }) => {
      state.isFetchingGpsData = payload;
    },
    reset: () => initialState,
  },
  extraReducers: (builder: ActionReducerMapBuilder<IVideoPlaybackGpsState>) => {
    builder
      .addMatcher(videoDownloadApi.endpoints.getVideoDownload.matchFulfilled, (state, { payload }) => {
        state.videoRequestStartDayjs = dayjs.parseZone(payload.start).set('millisecond', 0);
        state.videoRequestEndDayjs = dayjs.parseZone(payload.end);
        state.gpsJSONList = payload.gps;
        state.gpsData = [];
      }).addMatcher(videoDownloadApi.endpoints.getVideoDownloadTrackGpsData.matchFulfilled, (state, { payload }) => {
        state.gpsData = payload.reduce((acc, gpsData) => {
          const [
            date,
            lat,
            long,
            altitude,
            velocity,
            heading,
          ] = gpsData.split(';');

          const dateDayjs = dayjs.parseZone(date);

          if (dateDayjs.isSame(state.videoRequestStartDayjs)
          || dateDayjs.isBetween(state.videoRequestStartDayjs, state.videoRequestEndDayjs)
          || dateDayjs.isSame(state.videoRequestEndDayjs)
          ) {
            acc.push({
              date,
              dateDayjs,
              timeValue: dateDayjs.diff(state.videoRequestStartDayjs, 'ms'),
              lat: parseFloat(lat),
              long: parseFloat(long),
              altitude: parseFloat(altitude),
              velocity: parseFloat(velocity),
              heading: parseFloat(heading),
            });
          }

          return acc;
        }, [] as IGpsData[]);
      });
  },
});

export default slice.reducer;
