import { IUserGroup, IUserGroupPermissions, IUserGroupUpdate } from '@th-common/interfaces/user-groups/user-groups';
import { apiRoot } from '@th-common/store/api';

export const userGroupsApi = apiRoot.enhanceEndpoints({}).injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getUserGroups: builder.query<IUserGroup[], void>({
      query: () => 'user-groups',
    }),
    getUserGroupPermissions: builder.query<IUserGroupPermissions, number>({
      query: (groupId) => `user-groups/${groupId}`,
    }),
    updateUserGroup: builder.mutation<void, IUserGroupUpdate>({
      query: (update) => ({
        url: 'user-groups',
        method: 'PUT',
        body: update,
      }),
    }),
  }),
});
