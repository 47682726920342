
import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material/styles/createTheme';

function container(): Element {
  return document.fullscreenElement ?? document.body;
}

export const MuiTooltip: Components<Omit<Theme, 'components'>>['MuiTooltip'] = {
  defaultProps: {
    disableInteractive: true,
    PopperProps: {
      container,
    },
  },
};
