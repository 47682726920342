import { IUserGroupPermissionItem, IUserGroupPermissions, IUserGroupPermissionTreeItem } from '@th-common/interfaces/user-groups/user-groups';

export namespace PermissionUtils {
  export const getPermissionItemId = (permissionItem: IUserGroupPermissionItem): string => {
    return `${permissionItem.name}${permissionItem.permissionDto ? `-${permissionItem.permissionDto.id}` : ''}`;
  };

  export const splitNameByUpperCase = (name: string): string => {
    return name.replace(/([A-Z][a-z]+|[A-Z]+(?![a-z]))/g, ' $1').trim();
  };

  export const parsePermissionItem = (permissionItem: IUserGroupPermissionItem): [
    IUserGroupPermissionTreeItem,
    string[],
  ] => {
    const selectedChildrenItems: string[] = [];

    if (permissionItem.isSelected) {
      selectedChildrenItems.push(getPermissionItemId(permissionItem));
    }

    const [
      parsedChildrenItems,
      selectedItems,
    ] = permissionItem.children.reduce((acc, childItem) => {
      const [parsedChildItem, selectedChildItems] = parsePermissionItem(childItem);
      acc[0].push(parsedChildItem);
      acc[1].push(...selectedChildItems);
      return acc;
    }, [[], []] as [IUserGroupPermissionTreeItem[], string[]]);

    return [
      {
        id: getPermissionItemId(permissionItem),
        label: splitNameByUpperCase(permissionItem.name),
        isSelected: permissionItem.isSelected,
        children: parsedChildrenItems,
      },
      selectedChildrenItems.concat(selectedItems),
    ];
  };

  export const parseUserGroupPermissions = (userGroupPermissions: IUserGroupPermissions): [
    IUserGroupPermissionTreeItem[],
    string[],
  ] => {
    const groupPermissionsArray = userGroupPermissions ? [userGroupPermissions.permissions] : [];

    return groupPermissionsArray.reduce((acc, groupPermissionsItem) => {
      const [
        parsedGroupPermissionItem,
        selectedPermissionItems,
      ] = parsePermissionItem(groupPermissionsItem);
      acc[0].push(parsedGroupPermissionItem);
      acc[1].push(...selectedPermissionItems);
      return acc;
    }, [[], []] as [
      IUserGroupPermissionTreeItem[],
      string[],
    ]);
  };
}
