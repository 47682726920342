export namespace TimelineScaleUtils {
  export const getZoomDiffRelatedToHoveredFrame = (
    zoomDiff: number,
    hoveredFrame: number,
    scaleValue: [number, number],
  ): [number, number] => {
    const hoveredFrameToStart = hoveredFrame - scaleValue[0];
    const hoveredFrameToEnd = scaleValue[1] - hoveredFrame;

    const scaleDiff = scaleValue[1] - scaleValue[0];

    const zoomStartMultiplier = +(hoveredFrameToStart / scaleDiff).toFixed(3);
    const zoomEndMultiplier = +(hoveredFrameToEnd / scaleDiff).toFixed(3);

    const zoomStartDiff = zoomDiff * zoomStartMultiplier;
    const zoomEndDiff = zoomDiff * zoomEndMultiplier;

    return [zoomStartDiff, zoomEndDiff];
  };

  export const reachedMinScaleValue = (scaleValue: [number, number]): boolean => {
    const rangeDiff = scaleValue[1] - scaleValue[0];
    const halfMinuteInMs = 30 * 1000;

    return rangeDiff < halfMinuteInMs;
  };
}
