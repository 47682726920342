import { TPlaybackSpeedSettingsValue } from '@th-common/enums/video-playback/playback-speed.enum';
import { TViewLayout } from '@th-common/interfaces/player/player';
import { IVideoBlurring, IVideoSourceInfo } from '@th-common/interfaces/player/video-source-info';
import { IVideoBookmark } from '@th-common/interfaces/video/bookmark';
import { ICameraHlsData, IVideoTrackHls } from '@th-common/interfaces/video/video-request';
import { Dayjs } from 'dayjs';

export enum TPlayerMode {
  Archive = 'archive',
  Offline = 'offline',
  Online = 'online',
  None ='',
}

export interface IVideoPlaybackState {
  playerMode: TPlayerMode;
  docToken: string;
  deviceName: string;
  tracks: IVideoTrackHls[];
  cameraHlsDataByTrack: Record<number, ICameraHlsData>[];
  eventJSONList: string[];
  currentTrackIndex: number | null;
  cameras: IVideoSourceInfo[];
  audioSources: IVideoSourceInfo[];
  videoRequestStartDayjs: Dayjs;
  videoRequestEndDayjs: Dayjs;
  videoRequestMin: number;
  videoRequestMax: number;
  dirHandle: FileSystemDirectoryHandle | null;
  //
  playerCurrentTimeRelated: number;
  currentTimelineValue: number;
  masterPlayerNumber: number;
  clipValue: [number, number];
  clipDateTime: [string, string];
  videoDownloadId: number;
  maxFps: number;
  blurring: IVideoBlurring | null;
  bookmarks: IVideoBookmark[];
  activeBookmarkIndex: number | null;
  viewLayout: TViewLayout;
  isMetaDataPanelOpen: boolean;
  // Player
  showClip: boolean;
  hoveredFrame: number | null;
  hoveredFrameTooltip: string;
  hoveredFramePositionX: number;
  scaleValue: [number, number];
  playingForward: boolean;
  playingBackward: boolean;
  playbackSpeed: TPlaybackSpeedSettingsValue;
  fromFolder: boolean;
  bigCameraNumber: number | null;
  cameraNumbersCanPlay: number[];
  cameraNumbersBuffering: number[];
}
