import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import { videoDownloadApi } from '@th-common/api/video-download.api';
import {
  IVideoEventData, IVideoEventFiltersFormValues, IVideoEventState, IVideoEventUniqueList, TVideoEventFiltered,
} from '@th-common/interfaces/video/event-data';

function convertFormValueToFilters(formValues: IVideoEventFiltersFormValues): TVideoEventFiltered {
  return {
    name: formValues.name === 'All' ? null : formValues.name,
  };
}

export interface IVideoPlaybackEventState {
  eventJSONList: string[];
  eventData: IVideoEventData[];
  eventUniqueList: IVideoEventUniqueList[];
  eventDataFilteredList: IVideoEventUniqueList[];
  eventProperties: IVideoEventState[];
  lastEventDataUniqueIndex: number;
  currentFilter: TVideoEventFiltered;
}

export const currentFilter: TVideoEventFiltered = {
  name: null,
};

const initialState: IVideoPlaybackEventState = {
  eventJSONList: [],
  eventData: [],
  eventUniqueList: [],
  eventDataFilteredList: [],
  eventProperties: [],
  lastEventDataUniqueIndex: 0,
  currentFilter,
};

export const slice = createSlice({
  name: 'videoPlaybackEvent',
  initialState,
  reducers: {
    reset: () => initialState,
    setLastEventIndex: (state, { payload }: { payload: number }) => {
      state.lastEventDataUniqueIndex = payload;
    },
    setEventDataFilteredList: (state, { payload }: { payload: IVideoEventUniqueList[] }) => {
      state.eventDataFilteredList = payload;
    },
    formFilter: (state, { payload }: { payload: IVideoEventFiltersFormValues }) => {
      state.currentFilter = {
        ...state.currentFilter,
        ...convertFormValueToFilters(payload),
      };
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<IVideoPlaybackEventState>) => {
    builder
      .addMatcher(videoDownloadApi.endpoints.getVideoDownload.matchFulfilled, (state, { payload }) => {
        state.eventJSONList = payload.event;
      })
      .addMatcher(videoDownloadApi.endpoints.getVideoDownloadEventsData.matchFulfilled, (state, { payload }) => {
        state.eventData = payload.flatMap(
          ({ Data, Properties }) => {
            return Data.map(dataEntry => ({
              date: dataEntry.TS,
              states: Properties
                .map((prop, index) => ({
                  name: prop.Name,
                  value: prop.Values[dataEntry.State[index]],
                  isActiveState: dataEntry.State[index] === 2, // 0 - unset, 1 - inactive, 2 - active
                }))
                // Filter out the 'Recording Mode' property by name as it is not needed
                .filter(
                  ({ name }) => name !== 'Recording Mode',
                ),
            }));
          },
        );

        const firstEventDataItem = state.eventData[0];
        if (firstEventDataItem && firstEventDataItem.states.length > 0) {
          state.eventProperties = firstEventDataItem.states;
        }

        const entries = state.eventData;
        const output: IVideoEventUniqueList[] = [];
        if (entries.length !== 0) {
          const first = entries[0];
          first.states.forEach((eventState) => {
            output.push({
              datetime: first.date,
              state: eventState,
            });
          });

          const prevStates = new Map<string, string>();
          first.states.forEach(
            (eventState) => prevStates.set(eventState.name, eventState.value),
          );

          for (let i = 1; i < entries.length; i++) {
            const current = entries[i];
            current.states.forEach((eventState) => {
              if (prevStates.get(eventState.name) !== eventState.value) {
                output.push({
                  datetime: current.date,
                  state: eventState,
                });
              }
            });
            current.states.forEach(
              (eventState) => prevStates.set(eventState.name, eventState.value),
            );
          }
        }
        state.eventUniqueList = output;
        state.eventDataFilteredList = output;
      });
  },
});

export default slice.reducer;
