import { TReduxTag } from '@th-common/enums/redux-tags.enum';
import { IEditAndCreateVideoQueryBody, TMarkedVideoSearchRequest } from '@th-common/interfaces/marked-video';
import { apiRoot } from '@th-common/store/api';

export const markedVideoApiEndpoints = {
  getMarkedVideos: {
    query: (searchRequest: TMarkedVideoSearchRequest) => {
      return {
        url: 'marked-videos/all',
        method: 'POST',
        body: searchRequest,
      };
    },
    providesTags: [
      TReduxTag.MarkedVideo,
    ],
  },
  deleteMarkedVideo: {
    query: (id: number) => ({
      url: `marked-videos/${id}`,
      method: 'DELETE',
    }),
    invalidatesTags: [
      TReduxTag.MarkedVideo,
    ],
  },
  createVideoQueryFromMarkedVideo: {
    query: (parentId: number) => ({
      url: `marked-videos/${parentId}/video-queries/default`,
      method: 'POST',
      body: {},
    }),
    invalidatesTags: [
      TReduxTag.MarkedVideo,
    ],
  },
  createVideoQueryFromMarkedVideoCustom: {
    query: ({ parentId, data }: { parentId: number; data: IEditAndCreateVideoQueryBody }) => ({
      url: `marked-videos/${parentId}/video-queries/custom`,
      method: 'POST',
      body: data,
    }),
    invalidatesTags: [
      TReduxTag.MarkedVideo,
    ],
  },
};

export const markedVideoApi = apiRoot.enhanceEndpoints({}).injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    deleteMarkedVideo: builder.mutation<void, number>(markedVideoApiEndpoints.deleteMarkedVideo),
    createVideoQueryFromMarkedVideo: builder.mutation<void, number>(
      markedVideoApiEndpoints.createVideoQueryFromMarkedVideo,
    ),
    createVideoQueryFromMarkedVideoCustom: builder.mutation<void, { parentId: number; data: IEditAndCreateVideoQueryBody }>(
      markedVideoApiEndpoints.createVideoQueryFromMarkedVideoCustom,
    ),
  }),
});
