import { IVideoSourceInfo } from '@th-common/interfaces/player/video-source-info';
import { ICameraHlsData, IVideoTrackHls } from '@th-common/interfaces/video/video-request';
import dayjs, { Dayjs } from 'dayjs';

import { IVideoPlaybackState } from '../video-playback-state.interface';

export namespace VideoPlaybackUtils {
  export const getCurrentTimelineDayjs = (videoRequestStartDayjs: Dayjs, currentTimelineValue: number): Dayjs => {
    return videoRequestStartDayjs.add(currentTimelineValue, 'ms');
  };

  export const getDateTimelineValue = (videoRequestStartDayjs: Dayjs, dateTime: string): number => {
    return dayjs.parseZone(dateTime).diff(videoRequestStartDayjs, 'ms');
  };

  export const getTrackIndexByTimelineValue = (
    currentTimelineValue: number,
    tracks: IVideoTrackHls[],
    currentTrackIndex: number | null,
  ): number => {
    if (currentTrackIndex === null || (
      currentTimelineValue < tracks[currentTrackIndex].startTimelineValue
            || currentTimelineValue > tracks[currentTrackIndex].endTimelineValue)
    ) {
      return tracks.findIndex((track) => {
        return currentTimelineValue >= track.startTimelineValue && currentTimelineValue <= track.endTimelineValue;
      });
    }

    return currentTrackIndex;
  };

  export const getTimelineValueByPlayerTime = (
    playerTimeInMs: number,
    masterPlayerStartTimeline: number,
  ): number => {
    return masterPlayerStartTimeline + playerTimeInMs;
  };

  export const getPlayerCurrentTime = (
    timelineRelatedToTrack: number,
    cameraHlsData: ICameraHlsData,
  ): number => {
    const playerTime = (timelineRelatedToTrack - cameraHlsData.startRelatedToTrack) / 1000;
    return +playerTime.toFixed(6);
  };

  export const getMinTimelineValueByTrack = (
    cameraHlsDataByTrack: Record<number, ICameraHlsData>[],
    currentTrackIndex: number,
    tracks: IVideoTrackHls[],
  ): number => {
    const startTimelineValue = tracks[currentTrackIndex].startTimelineValue;
    const cameraHlsDataValues = Object.values(cameraHlsDataByTrack[currentTrackIndex]);

    return cameraHlsDataValues.reduce((acc, camera) => {
      if (camera.startTimelineValue < startTimelineValue) {
        return 0;
      }

      return Math.min(acc, camera.startTimelineValue);
    }, cameraHlsDataValues[0].startTimelineValue);
  };

  export const getMaxFpsByTrack = (
    cameraHlsDataByTrack: Record<number, ICameraHlsData>[],
    currentTrackIndex: number,
    cameras: IVideoSourceInfo[],
  ): number => {
    const visibleCameraNumbers = cameras.filter((camera) => !camera.isPlayerHide).map((camera) => camera.number);
    return Math.max(
      ...Object.values(cameraHlsDataByTrack[currentTrackIndex])
        .filter((camera) => visibleCameraNumbers.includes(camera.number))
        .map((camera) => camera.fps),
    );
  };

  export const getMasterCameraNumber = (
    cameraHlsDataByTrack: Record<number, ICameraHlsData>[],
    currentTrackIndex: number,
    timelineValue: number,
  ): number => {
    const currentCameraHlsData = cameraHlsDataByTrack[currentTrackIndex];
    const cameraHlsDataByTrackValues = Object.values(currentCameraHlsData);
    const [
      minStartTimelineCameraNumber,
      maxEndTimelineCameraNumber,
      currentTimelineCameraNumber,
    ] = cameraHlsDataByTrackValues.reduce((acc, cameraData) => {
      if (cameraData.startTimelineValue < currentCameraHlsData[acc[0]].startTimelineValue) {
        acc[0] = cameraData.number;
      }

      if (cameraData.endTimelineValue > currentCameraHlsData[acc[1]].endTimelineValue) {
        acc[1] = cameraData.number;
      }

      if (timelineValue >= cameraData.startTimelineValue && timelineValue <= cameraData.endTimelineValue) {
        acc[2] = cameraData.number;
      }

      return acc;
    }, Array(3).fill(cameraHlsDataByTrackValues[0].number));

    if (timelineValue < cameraHlsDataByTrack[currentTrackIndex][minStartTimelineCameraNumber].startTimelineValue) {
      return minStartTimelineCameraNumber;
    }

    if (timelineValue > cameraHlsDataByTrack[currentTrackIndex][maxEndTimelineCameraNumber].endTimelineValue) {
      return maxEndTimelineCameraNumber;
    }

    return currentTimelineCameraNumber;
  };

  export const roundTimelineValue = (timelineValue: number): number => {
    return +(timelineValue.toFixed(3));
  };

  export const updateCameraNumbersCanPlay = (state: IVideoPlaybackState): Pick<IVideoPlaybackState, 'cameraNumbersCanPlay'> => {
    if (state.currentTrackIndex === null || state.currentTrackIndex === -1) {
      return {
        cameraNumbersCanPlay: [],
      };
    }

    const cameraHlsDataByTrackValues = Object.values(state.cameraHlsDataByTrack[state.currentTrackIndex]);
    if (state.cameraNumbersCanPlay.length === cameraHlsDataByTrackValues.length) {
      return {
        cameraNumbersCanPlay: state.cameraNumbersCanPlay,
      };
    }

    return {
      cameraNumbersCanPlay: cameraHlsDataByTrackValues
        .filter((cameraData) => state.currentTimelineValue >= cameraData.startTimelineValue)
        .map((cameraData) => cameraData.number),
    };
  };

  export const updateTimelineValue = (state: IVideoPlaybackState): Pick<IVideoPlaybackState, 'cameraNumbersCanPlay' | 'currentTimelineValue'> => {
    return {
      currentTimelineValue: roundTimelineValue(state.currentTimelineValue),
      ...updateCameraNumbersCanPlay(state),
    };
  };

  export const returnStateAfterTrackChange = (
    state: IVideoPlaybackState,
  ): Partial<IVideoPlaybackState> => {
    const currentTrackIndex = state.currentTrackIndex;

    if (currentTrackIndex === null || currentTrackIndex === -1) {
      return {
        playingForward: false,
        playingBackward: false,
        currentTrackIndex: null,
        masterPlayerNumber: -1,
        playerCurrentTimeRelated: 0,
        ...updateTimelineValue(state),
      };
    }

    const masterPlayerNumber = getMasterCameraNumber(state.cameraHlsDataByTrack, currentTrackIndex, state.currentTimelineValue);

    const masterPlayerStartTimeline = state.cameraHlsDataByTrack[currentTrackIndex][masterPlayerNumber].startTimelineValue;
    const masterPlayerEndTimeline = state.cameraHlsDataByTrack[currentTrackIndex][masterPlayerNumber].endTimelineValue;

    let timelineValue = state.currentTimelineValue;

    if (timelineValue < masterPlayerStartTimeline) {
      timelineValue = masterPlayerStartTimeline;
    }

    if (timelineValue > masterPlayerEndTimeline) {
      timelineValue = masterPlayerEndTimeline;
    }

    const {
      currentTimelineValue,
      cameraNumbersCanPlay,
    } = updateTimelineValue({
      ...state,
      currentTimelineValue: timelineValue,
    });

    const timelineRelatedToTrack = currentTimelineValue - state.tracks[currentTrackIndex].startTimelineValue;
    const playerCurrentTimeRelated = VideoPlaybackUtils.roundTimelineValue(timelineRelatedToTrack) / 1000;

    return {
      currentTrackIndex: state.currentTrackIndex,
      masterPlayerNumber,
      playerCurrentTimeRelated: +playerCurrentTimeRelated.toFixed(6),
      currentTimelineValue,
      cameraNumbersCanPlay,
    };
  };
}
