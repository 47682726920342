
import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import { userGroupsApi } from '@th-common/api/user-group.api';
import {
  IUserGroup, IUserGroupDevices, IUserGroupPermissionTreeItem,
} from '@th-common/interfaces/user-groups/user-groups';

import { PermissionUtils } from './utils/permission-utils';

export interface IUserGroupsState {
  userGroups: IUserGroup[];
  groupPermissions: IUserGroupPermissionTreeItem[];
  initialSelectedPermissions: string[];
  selectedGroupId: number | null;
  selectedUserId: number | null;
  selectedGroupPermissions: string[];
  initialCanAccessAllDevices: boolean;
  canAccessAllDevices: boolean;
  userGroupDevices: IUserGroupDevices[];
  initialUserGroupDeviceIds: number[];
  selectedUserGroupDeviceIds: number[];
  updatingUserGroupInProgress: boolean;
}

const initialState: IUserGroupsState = {
  userGroups: [],
  groupPermissions: [],
  initialSelectedPermissions: [],
  selectedGroupId: null,
  selectedUserId: null,
  selectedGroupPermissions: [],
  initialCanAccessAllDevices: false,
  canAccessAllDevices: false,
  userGroupDevices: [],
  initialUserGroupDeviceIds: [],
  selectedUserGroupDeviceIds: [],
  updatingUserGroupInProgress: false,
};

export const slice = createSlice({
  name: 'user-groups',
  initialState,
  reducers: {
    reset: () => initialState,
    setSelectedGroupId: (state, { payload }: { payload: number | null }) => {
      state.selectedGroupId = payload;
      state.groupPermissions = [];
      state.initialSelectedPermissions = [];
      state.selectedGroupPermissions = [];
      state.userGroupDevices = [];
      state.initialUserGroupDeviceIds = [];
      state.selectedUserGroupDeviceIds = [];
      state.updatingUserGroupInProgress = false;
    },
    setSelectedUserId: (state, { payload }: { payload: number | null }) => {
      state.selectedUserId = payload;
    },
    setSelectedGroupPermissions: (state, { payload }: { payload: string[] }) => {
      state.selectedGroupPermissions = payload;
    },
    setInitialSelectedPermissions: (state, { payload }: { payload: string[] }) => {
      state.initialSelectedPermissions = payload;
    },
    setInitialUserGroupDeviceIds: (state, { payload }: { payload: number[] }) => {
      state.initialUserGroupDeviceIds = payload;
    },
    setInitialCanAccessAllDevices: (state, { payload }: { payload: boolean }) => {
      state.initialCanAccessAllDevices = payload;
    },
    setCanAccessAllDevices: (state, { payload }: { payload: boolean }) => {
      state.canAccessAllDevices = payload;
    },
    selectUserGroupDevice: (state, { payload }: { payload: number }) => {
      state.selectedUserGroupDeviceIds.push(payload);
    },
    setUpdatingUserGroupInProgress: (state, { payload }: { payload: boolean }) => {
      state.updatingUserGroupInProgress = payload;
    },
    unselectUserGroupDevice: (state, { payload }: { payload: number }) => {
      state.selectedUserGroupDeviceIds = state.selectedUserGroupDeviceIds.filter((id) => id !== payload);
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<IUserGroupsState>) => {
    builder.addMatcher(userGroupsApi.endpoints.getUserGroupPermissions.matchFulfilled, (state, { payload }) => {
      const [
        groupPermissions,
        initialSelectedPermissions,
      ] = PermissionUtils.parseUserGroupPermissions(payload);

      state.initialCanAccessAllDevices = payload.canAccessAllDevices;
      state.canAccessAllDevices = payload.canAccessAllDevices;
      state.groupPermissions = groupPermissions;
      state.initialSelectedPermissions = initialSelectedPermissions;
      state.selectedGroupPermissions = initialSelectedPermissions;
      state.userGroupDevices = payload.userGroupDevices;
      const initialUserGroupDeviceIds = payload.userGroupDevices
        .filter((device) => device.isSelected)
        .map((device) => device.configGroupId);
      state.initialUserGroupDeviceIds = initialUserGroupDeviceIds;
      state.selectedUserGroupDeviceIds = initialUserGroupDeviceIds;
    });
  },
});

export default slice.reducer;
